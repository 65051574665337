import React from 'react';
import styles from "./popUp.module.css"
const Popup = ({ children }) => {
    return (
        <div className={styles.container}>
            <div className={styles.popup}>
                {children}
            </div>
        </div>
    );
};

export default Popup;
import {
  BrowserRouter,
  Routes,
  Route,
  useRoutes,
  useNavigate,
} from "react-router-dom";
import { LoginPage } from "../Pages/Login/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { SideNavBar } from "../Components/SideNavBar/SideNavBar";
import { DashboardOverview } from "../Pages/DashboardOverview/DashboardOverview";
import { ViewClient } from "../Pages/ViewClient/ViewClient";
import { SpecificClientApplications } from "../Pages/SpecificClientApplications/SpecificClientApplications";
import { CreateClient } from "../Pages/CreateClient/CreateClient";
import { AllApplications } from "../Pages/AllApplications/AllApplications";
import { NewJobRequirement } from "../Pages/NewJobRequirement/NewJobRequirement";
import { CreateJob } from "../Pages/CreateJob/CreateJob";
import { Header } from "../Components/common/header/header";
import { useEffect, useState } from "react";

export const AllRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LoginPage />}></Route>
        <Route exact path="/*" element={<SubRoutes />}></Route>
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};

const SubRoutes = () => {
  const renderSubRoutes = useRoutes([
    { path: "/dashboard-overview", element: <DashboardOverview /> },
    { path: "/view-client", element: <ViewClient /> },
    { path: "/create-client", element: <CreateClient /> },
    { path: "/applications", element: <AllApplications /> },
    { path: "/specific-client", element: <SpecificClientApplications /> },
    { path: "/new-application", element: <NewJobRequirement /> },
    { path: "/create-job", element: <CreateJob /> },
  ]);

  return (
    <>
      {sessionStorage.getItem("loginSuccess") ? (
        <>
          {" "}
          <SideNavBar />
          <Header />
          {renderSubRoutes}{" "}
        </>
      ) : (
        <NavigateToLogin />
      )}
    </>
  );
};

const NavigateToLogin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("navigateToLogin=>");
    navigate("/");
  }, []);
  return <></>;
};

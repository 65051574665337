const BASE_URL = process.env.REACT_APP_BASE_URL;
// const CSM_SERVICE = ":8402";
// const DEVELOPER = ":8100";

const CSM_SERVICE = "/clsm";
const DEVELOPER = "/developer";
const AUTHENTICATION = "/authentication";

export const CLIENT_DETAILS = `${BASE_URL}${CSM_SERVICE}/api/v1/clientsm/get/list/client`;
export const CLIENT_BY_ID = `${BASE_URL}${CSM_SERVICE}/api/v1/clientsm/get/client/details`;
export const ALL_APPLICATIONS_OF_CLIENT = `${BASE_URL}${CSM_SERVICE}/api/v1/clientsm/get/client/list/jobs`;
export const VIEW_PDF = `${BASE_URL}${CSM_SERVICE}/api/v1/clientsm/view/job/description`;
export const NEW_CLIENT = `${BASE_URL}${CSM_SERVICE}/api/v1/clientsm/create/new/client`;
export const UPDATE_CLIENT = `${BASE_URL}${CSM_SERVICE}/api/v1/clientsm/update/existing/client`;
export const ALL_JOB_APPLICATIONS = `${BASE_URL}${CSM_SERVICE}/api/v1/clientsm/get/filtered/jobs`;
export const DOWNLOAD_PDF = `${BASE_URL}${CSM_SERVICE}/api/v1/clientsm/download/job/description/file`;
export const ALL_CLIENTS = `${BASE_URL}${CSM_SERVICE}/api/v1/clientsm/get/client/name/id/list`;
export const CREATE_JOB = `${BASE_URL}${CSM_SERVICE}/api/v1/clientsm/create/new/job/requirement`;
export const UPLOAD_JD = `${BASE_URL}${CSM_SERVICE}/api/v1/clientsm/upload/job/description`;
export const CREATE_JOB_UPLOAD_FILE = `${BASE_URL}${CSM_SERVICE}/api/v1/clientsm/create/job/upload/file`;
export const CLOSE_APPLICATIONS = `${BASE_URL}${CSM_SERVICE}/api/v1/clientsm/close/job/requirements`;
export const UPDATE_JOB = `${BASE_URL}${CSM_SERVICE}/api/v1/clientsm/update/existing/job/file/upload`;
export const CLSM_LOGIN = `${BASE_URL}${AUTHENTICATION}/api/v1/user/login/role`;

export const GET_COUNTRIES = `${BASE_URL}${DEVELOPER}/api/v1/country/getcountries`;
import axios from "axios";
import { CLOSE_APPLICATIONS } from "./URLs";

export const closeJobApplications = async (data) => {
  const apiUrl = CLOSE_APPLICATIONS;

  const Config = {};

  return await axios.post(apiUrl, data, Config).then((res) => res.data);
};

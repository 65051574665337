import axios from "axios";
import { ALL_CLIENTS } from "./URLs";

export const getAllClients = async () => {
  const apiUrl = ALL_CLIENTS;
  const Config = {};
  if (sessionStorage?.getItem("email")) {
  } else {
    throw new Error("please login again!");
  }
  const data = {
    // clsmId: "clsm@wissda.com",
    clsmId: sessionStorage?.getItem("email"),
  };
  // return await axios.get(apiUrl).then((response) => response.data);
  return await axios.post(apiUrl, data, Config).then((res) => res.data);
};

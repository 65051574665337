import { useEffect, useState } from "react";
import "./ExistingClient.scss";
import { toast } from "react-toastify";
import { getAllClients } from "../../APIs/allClients";
import Dropdown from "react-dropdown";
import { getClientById } from "../../APIs/clientDetailById";
import { useNavigate } from "react-router-dom";

export const ExistingCLient = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState({});
  const [clientDetails, setClientDetails] = useState({});

  const createList = (list) => {
    let temp = [];
    list.forEach((element) => {
      temp.push({
        value: element?.clientId,
        label: element?.clientName,
      });
    });
    return temp;
  };

  const allClientns = async () => {
    try {
      const res = await getAllClients();
      if (res) {
        const list = createList(res);
        setClients([...list]);
      }
    } catch (err) {
      console.log("allclients error ==>", err);
      toast(err.message);
    }
  };

  const existingClientDetails = async (clientId) => {
    try {
      const res = await getClientById(clientId);
      if (res) {
        setClientDetails({ ...res });
      }
    } catch (err) {
      console.log("existingClientDetails error ==>", err);
      setClientDetails({});
    }
  };

  const handleNext = () => {
    if (clientDetails?.clientId) {
      sessionStorage.setItem("clientType", "old");
      sessionStorage.setItem("clientId", clientDetails?.clientId);
      navigate("/create-job");
    }
  };

  useEffect(() => {
    allClientns();
  }, []);

  useEffect(() => {
    // console.log(selectedClient.value);
    existingClientDetails(selectedClient.value);
  }, [selectedClient]);
  return (
    <div className="existingClient">
      <div className="first">
        <div>
          <label>Client Name</label>
          <Dropdown
            options={clients}
            onChange={(e) => setSelectedClient(e)}
            value={selectedClient?.label}
            placeholder="Select an option"
            className="dropdown"
            controlClassName="myControlClassName"
            menuClassName="myMenuClassName"
            myArrowClassName="myArrowClassName"
          />
        </div>
        {clientDetails?.spocFullName && (
          <div className="spoc">
            <label>SPOC</label>
            <input type="text" value={clientDetails?.spocFullName} disabled />
          </div>
        )}
      </div>
      {clientDetails?.spocFullName && (
        <ViewClientDetail clientDetails={clientDetails} />
      )}
      <div>
        <button className="next" onClick={() => handleNext()}>
          Next
        </button>
      </div>
    </div>
  );
};

const ViewClientDetail = ({ clientDetails }) => {
  return (
    <div className="details">
      <div className="titleC">Client Details</div>
      <div className="subDetails">
        <div>
          <div>Date</div>
          <div>{clientDetails?.date}</div>
        </div>
        <div>
          <div>Client ID</div>
          <div>{clientDetails?.clientId}</div>
        </div>
        <div>
          <div>Website</div>
          <div>{clientDetails?.companyWebsite}</div>
        </div>
        <div>
          <div>SPOC Full Name</div>
          <div>{clientDetails?.spocFullName}</div>
        </div>
      </div>
      <div className="subDetails">
        <div>
          <div>Email</div>
          <div>{clientDetails?.clientEmail}</div>
        </div>
        <div>
          <div>Contact Number</div>
          <div>{clientDetails?.phoneNumber}</div>
        </div>
        <div>
          <div>Business Unit</div>
          <div>{clientDetails?.businessUnit}</div>
        </div>
        <div>
          <div>Designation</div>
          <div>{clientDetails?.designation}</div>
        </div>
      </div>
      <div className="subDetails2">
        <div>
          <div>Address</div>
          <div>{clientDetails?.companyAddress}</div>
        </div>
        <div>
          <div>Country</div>
          <div>{clientDetails?.country}</div>
        </div>
        <div>
          <div>State</div>
          <div>{clientDetails?.state}</div>
        </div>
        <div>
          <div>City</div>
          <div>{clientDetails?.city}</div>
        </div>
      </div>
    </div>
  );
};

import axios from "axios";
import { ALL_JOB_APPLICATIONS } from "./URLs";

export const getAllJobApplications = async (qeuryParams) => {
  const apiUrl = ALL_JOB_APPLICATIONS;

  // Use the `params` property to include query parameters in the GET request
  return await axios
    .get(apiUrl, {
      params: qeuryParams,
    })
    .then((response) => response);
};

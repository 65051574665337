import { useEffect, useState } from "react";
import "./NewClient.scss";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getAllCountries } from "../../APIs/getCountries";
import Dropdown from "react-dropdown";

export const NewClientDetails = () => {
  const navigate = useNavigate();
  const [clientDetails, setClientDetails] = useState({
    companyName: "",
    companyWebsite: "",
    companyAddress: "",
    country: "",
    state: "",
    city: "",
    spocFirst: "",
    spocSecond: "",
    email: "",
    phone: "",
    businessUnit: "",
    designation: "",
  });

  const [countries, setCountries] = useState([]);

  const allCountries = async () => {
    try {
      const res = await getAllCountries();
      if (res) {
        setCountries([...res]);
      }
    } catch (err) {
      console.log("all country==>", err.message);
    }
  };

  const handleChange = (e) => {
    // console.log(e.target.name, e.target.value);
    const temp = clientDetails;
    temp[e.target.name] = e.target.value;
    setClientDetails({ ...temp });
  };

  const handleCountryChange = (e) => {
    const temp = clientDetails;
    temp["country"] = e.value;
    setClientDetails({ ...temp });
  };

  const handleSaveAndProceed = () => {
    try {
      let proceed = true;
      for (const key in clientDetails) {
        if (clientDetails[key] === "") {
          proceed = false;
          throw new Error(`Field '${key}' cannot be empty.`);
        }
      }
      if (proceed) {
        const formattedClient = JSON.stringify(clientDetails);
        sessionStorage.setItem("clientDetails", formattedClient);
        sessionStorage.setItem("clientType", "new");
        navigate("/create-job");
      }
    } catch (err) {
      console.log("handleSaveAndProceed ==>", err);
      toast(err.message);
    }
  };

  useEffect(() => {
    allCountries();
  }, []);

  return (
    <div className="newClient">
      <div className="Allfields">
        <div className="subField1">
          <div className="subField2">
            <label>Company Name</label>
            <input
              type="text"
              value={clientDetails?.companyName}
              placeholder="Company Name"
              name="companyName"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="subField2">
            <label>Company Website</label>
            <input
              type="text"
              value={clientDetails?.companyWebsite}
              placeholder="Company Website"
              name="companyWebsite"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="subField1">
          <div className="subField2">
            <label>Company Address </label>
            <textarea
              name="companyAddress"
              value={clientDetails?.companyAddress}
              placeholder="Company Address "
              onChange={(e) => handleChange(e)}
            ></textarea>
          </div>
        </div>
        <div className="subField1">
          <div className="subField2">
            {/* <label>Country</label>
            <input
              type="text"
              value={clientDetails?.country}
              placeholder="Country"
              name="country"
              onChange={(e) => handleChange(e)}
            />
          </div> */}

            <div className="subField2">
              <label>Country</label>
              <Dropdown
                options={countries}
                onChange={(e) => handleCountryChange(e)}
                value={clientDetails?.country}
                placeholder="Country"
                className="dropdown"
                controlClassName="myControlClassName"
                menuClassName="myMenuClassName"
                arrowClassName="myArrowClassName"
                placeholderClassName="placeholderClassName"
              />
            </div>
          </div>

          <div className="subField2">
            <label>State</label>
            <input
              type="text"
              placeholder="State"
              name="state"
              value={clientDetails?.state}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="subField2">
            <label>City</label>
            <input
              type="text"
              placeholder="City"
              name="city"
              value={clientDetails?.city}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="subField1">
          <div className="subField2">
            <label>SPOC First Name</label>
            <input
              type="text"
              value={clientDetails?.spocFirst}
              placeholder="SPOC First Name"
              name="spocFirst"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="subField2">
            <label>SPOC Last Name</label>
            <input
              type="text"
              value={clientDetails?.spocSecond}
              placeholder="SPOC Last Name"
              name="spocSecond"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="subField1">
          <div className="subField2">
            <label>Email</label>
            <input
              type="text"
              placeholder="Email"
              name="email"
              value={clientDetails?.email}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="subField2">
            <label>Contact Number</label>
            <input
              type="tel"
              placeholder="Contact Number"
              name="phone"
              value={clientDetails?.phone}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className="subField1">
          <div className="subField2">
            <label>Business Unit</label>
            <input
              type="text"
              placeholder="Business Unit"
              name="businessUnit"
              value={clientDetails?.businessUnit}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="subField2">
            <label>Designation</label>
            <input
              type="tel"
              placeholder="Designation"
              name="designation"
              value={clientDetails?.designation}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="saveAndProceed">
        <button onClick={() => handleSaveAndProceed()}>Save & Proceed</button>
      </div>
    </div>
  );
};

import { useNavigate } from "react-router-dom";
import "./CreateClient.scss";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useEffect, useState } from "react";
import { registerNewClient, updateExistingClient } from "../../APIs/newClient";
import { toast } from "react-toastify";
import { getAllCountries } from "../../APIs/getCountries";
import Dropdown from "react-dropdown";

export const CreateClient = () => {
  const [edit, setEdit] = useState(false);
  const [clientId, setClientId] = useState("");
  const [clientDetails, setClientDetails] = useState({
    companyName: "",
    companyWebsite: "",
    companyAddress: "",
    country: "",
    state: "",
    city: "",
    spocFirst: "",
    spocSecond: "",
    email: "",
    phone: "",
    businessUnit: "",
    designation: "",
  });
  const [countries, setCountries] = useState([]);
  const navigate = useNavigate();

  const allCountries = async () => {
    try {
      const res = await getAllCountries();
      if (res) {
        setCountries([...res]);
      }
    } catch (err) {
      console.log("all country==>", err.message);
    }
  };

  const getClientDetails = async () => {
    const details = JSON.parse(sessionStorage.getItem("clientDetails"));
    if (details) {
      console.log(details);
      const temp = {
        companyName: details?.companyName,
        companyWebsite: details?.companyWebsite,
        companyAddress: details?.companyAddress,
        country: details?.country,
        state: details?.state,
        city: details?.city,
        spocFirst: details?.spocFirstName,
        spocSecond: details?.spocLastName,
        email: details?.clientEmail,
        phone: details?.phoneNumber,
        businessUnit: details?.businessUnit,
        designation: details?.designation,
      };
      setClientDetails({ ...temp });
      setEdit(true);
      setClientId(details?.clientId);
    }
  };

  const goToHome = () => {
    navigate("/view-client");
  };

  const createNewClient = async () => {
    try {
      //   console.log("edit", edit);
      if (!edit) {
        const res = await registerNewClient(clientDetails);
        if (res) {
          navigate("/view-client");
        }
      } else {
        const res = await updateExistingClient(clientDetails, clientId);
        if (res) {
          navigate("/view-client");
        }
      }
    } catch (err) {
      console.log("createNewClient error==>", err);
      toast(err.message);
    }
  };

  useEffect(() => {
    getClientDetails();
    allCountries();
    return () => {
      sessionStorage.removeItem("clientDetails");
    };
  }, []);
  return (
    <>
      <div className="create-client">
        <div className="backButton">
          <button onClick={() => goToHome()}>
            <ChevronLeftIcon /> <span> Clients </span>
          </button>
        </div>
        <AllInputFields
          clientDetails={clientDetails}
          setClientDetails={setClientDetails}
          countries={countries}
        />
        <div>
          <button className="update" onClick={() => createNewClient()}>
            Update
          </button>
        </div>
      </div>
    </>
  );
};

const AllInputFields = ({ clientDetails, setClientDetails, countries }) => {
  const handleChange = (e) => {
    // console.log(e.target.name, e.target.value);
    const temp = clientDetails;
    temp[e.target.name] = e.target.value;
    setClientDetails({ ...temp });
  };

  const handleCountryChange = (e) => {
    const temp = clientDetails;
    temp["country"] = e.value;
    setClientDetails({ ...temp });
  };
  return (
    <div className="Allfields">
      <div className="subField1">
        <div className="subField2">
          <label>Company Name</label>
          <input
            type="text"
            value={clientDetails?.companyName}
            placeholder="Company Name"
            name="companyName"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="subField2">
          <label>Company Website</label>
          <input
            type="text"
            value={clientDetails?.companyWebsite}
            placeholder="Company Website"
            name="companyWebsite"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="subField1">
        <div className="subField2">
          <label>Company Address </label>
          <textarea
            name="companyAddress"
            value={clientDetails?.companyAddress}
            placeholder="Company Address "
            onChange={(e) => handleChange(e)}
          ></textarea>
        </div>
      </div>
      <div className="subField1">
        {/* <div className="subField2">
          <label>Country</label>
          <input
            type="text"
            value={clientDetails?.country}
            placeholder="Country"
            name="country"
            onChange={(e) => handleChange(e)}
          />
        </div> */}
        <div className="subField2">
          <label>Country</label>
          <Dropdown
            options={countries}
            onChange={(e) => handleCountryChange(e)}
            value={clientDetails?.country}
            placeholder="Country"
            className="dropdown"
            controlClassName="myControlClassName"
            menuClassName="myMenuClassName"
            arrowClassName="myArrowClassName"
            placeholderClassName="placeholderClassName"
          />
        </div>
        <div className="subField2">
          <label>State</label>
          <input
            type="text"
            placeholder="State"
            name="state"
            value={clientDetails?.state}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="subField2">
          <label>City</label>
          <input
            type="text"
            placeholder="City"
            name="city"
            value={clientDetails?.city}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="subField1">
        <div className="subField2">
          <label>SPOC First Name</label>
          <input
            type="text"
            value={clientDetails?.spocFirst}
            placeholder="SPOC First Name"
            name="spocFirst"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="subField2">
          <label>SPOC Last Name</label>
          <input
            type="text"
            value={clientDetails?.spocSecond}
            placeholder="SPOC Last Name"
            name="spocSecond"
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="subField1">
        <div className="subField2">
          <label>Email</label>
          <input
            type="text"
            placeholder="Email"
            name="email"
            value={clientDetails?.email}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="subField2">
          <label>Contact Number</label>
          <input
            type="tel"
            placeholder="Contact Number"
            name="phone"
            value={clientDetails?.phone}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="subField1">
        <div className="subField2">
          <label>Business Unit</label>
          <input
            type="text"
            placeholder="Business Unit"
            name="businessUnit"
            value={clientDetails?.businessUnit}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="subField2">
          <label>Designation</label>
          <input
            type="tel"
            placeholder="Designation"
            name="designation"
            value={clientDetails?.designation}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
    </div>
  );
};

import { useNavigate } from "react-router-dom";
import "./CreateJob.scss";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import { getAllCountries } from "../../APIs/getCountries";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import { registerNewClient } from "../../APIs/newClient";
import { createJobAndUploadJd, updateExistingJob } from "../../APIs/createJob";
// import { uploadJobDoc } from "../../APIs/uploadJd";

export const CreateJob = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fieldValues, setFieldsValues] = useState({
    jobRole: "",
    designation: "",
    businessUnit: "",
    country: "",
    state: "",
    workLocation: "",
    typeOfEngagement: "",
    minYearsOfExperience: "",
    mandarotySkill: [],
    additionalSkill: [],
    availibility: "",
    minimumRating: "",
    numberOfOpenings: "",
  });
  const [countries, setCountries] = useState([]);

  const allCountries = async () => {
    try {
      const res = await getAllCountries();
      if (res) {
        setCountries([...res]);
      }
    } catch (err) {
      console.log("all country==>", err.message);
    }
  };

  const createNewClient = async () => {
    // console.log("createNewClient");
    try {
      const details = JSON.parse(sessionStorage.getItem("clientDetails"));
      if (details) {
        console.log(details);
        const temp = {
          companyName: details?.companyName,
          companyWebsite: details?.companyWebsite,
          companyAddress: details?.companyAddress,
          country: details?.country,
          state: details?.state,
          city: details?.city,
          spocFirst: details?.spocFirst,
          spocSecond: details?.spocSecond,
          email: details?.email,
          phone: details?.phone,
          businessUnit: details?.businessUnit,
          designation: details?.designation,
        };
        const res = await registerNewClient(temp);
        if (res) return res;
      }
    } catch (err) {
      console.log("createNewClient error==>", err);
      toast(err.message);
    }
  };

  const createNewJobReq = async (cliendId) => {
    try {
      if (sessionStorage?.getItem("email")) {
      } else {
        throw new Error("please login again!");
      }
      const data = {
        jobPosition: fieldValues?.jobRole,
        designation: fieldValues?.designation,
        division: fieldValues?.businessUnit,
        // clsmId: "clsm@wissda.com",
        clsmId: sessionStorage.getItem("email"),
        country: fieldValues?.country,
        state: fieldValues?.state,
        location: fieldValues?.workLocation,
        mandatorySkills: fieldValues?.mandarotySkill,
        skills: fieldValues?.additionalSkill,
        minimumCandidateRatings: Number(fieldValues?.minimumRating),
        dateOfJoining: fieldValues?.availibility,
        numberOfOpenings: fieldValues?.numberOfOpenings,
        minYearsOfExperience: Number(fieldValues?.minYearsOfExperience),
        typeOfEngagement: fieldValues?.typeOfEngagement,
      };

      // ----------- used to create only job
      // const res = await creNewJobRequirement(data, cliendId);

      // ------------ to create job and upload file together
      const res = await createJobAndUploadJd(data, selectedFile, cliendId);

      return res;
    } catch (err) {
      console.log("createNewJobReq error ==>", err);
      toast(err.message);
    }
  };

  // -------------- used to upload file -------------
  // const uploadFile = async (id) => {
  //   try {
  //     const res = await uploadJobDoc(selectedFile, id);
  //     return res;
  //   } catch (err) {
  //     console.log("error ==>", err);
  //   }
  // };

  //   if have to edit the page
  const preFillDetails = () => {
    const data = sessionStorage.getItem("jobData");
    const jobData = JSON.parse(data);
    console.log("jobData==>", jobData);
    const temp = {
      jobRole: jobData?.jobPosition,
      designation: jobData?.designation,
      businessUnit: jobData?.division,
      country: jobData?.country,
      state: jobData?.state,
      workLocation: jobData?.location,
      typeOfEngagement: jobData?.typeOfEngagement,
      minYearsOfExperience: jobData?.minYearsOfExperience,
      additionalSkill: [...jobData?.skills],
      mandarotySkill: [...jobData?.mandatorySkills],
      availibility: jobData?.dateOfJoining,
      minimumRating: jobData?.minimumCandidateRatings,
      numberOfOpenings: jobData?.numberOfOpenings,
    };
    // console.log(jobData?.skills);
    setFieldsValues({ ...temp });
  };

  // update existing job details
  const UpdateJob = async (cliendId) => {
    try {
      if (sessionStorage?.getItem("email")) {
      } else {
        throw new Error("please login again!");
      }
      const data = {
        jobPosition: fieldValues?.jobRole,
        designation: fieldValues?.designation,
        division: fieldValues?.businessUnit,
        // clsmId: "clsm@wissda.com",
        clsmId: sessionStorage.getItem("email"),
        country: fieldValues?.country,
        state: fieldValues?.state,
        location: fieldValues?.workLocation,
        mandatorySkills: fieldValues?.mandarotySkill,
        skills: fieldValues?.additionalSkill,
        minimumCandidateRatings: Number(fieldValues?.minimumRating),
        dateOfJoining: fieldValues?.availibility,
        numberOfOpenings: fieldValues?.numberOfOpenings,
        minYearsOfExperience: Number(fieldValues?.minYearsOfExperience),
        typeOfEngagement: fieldValues?.typeOfEngagement,
      };

      const dataS = sessionStorage.getItem("jobData");
      const jobData = JSON.parse(dataS);
      if (selectedFile) {
        const res = await updateExistingJob(data, selectedFile, jobData?.jobId);
        return res;
      } else {
        const res = await updateExistingJob(data, null, jobData?.jobId);
        return res;
      }
    } catch (err) {
      console.log("UpdateJob error ==>", err);
      toast(err.message);
    }
  };

  //   will call functions as per flow
  const FlowForPage = async () => {
    try {
      if (sessionStorage.getItem("clientType") === "new") {
        if (!selectedFile) {
          throw new Error("Upload Jd file");
          return;
        }

        const id = await createNewClient();
        if (id) {
          const resId = await createNewJobReq(id);
          // --------- when file is uploaded separately -----------
          // if (resId) {
          //   const ress = await uploadFile(resId);
          //   if (ress) {
          //     navigate("/applications");
          //   }
          // }
          // --------- when file is uploaded separately -----------
          if (resId) {
            navigate("/applications");
          }
        }
      } else if (sessionStorage.getItem("clientType") === "old") {
        if (!selectedFile) {
          throw new Error("Upload Jd file");
          return;
        }
        const clientId = sessionStorage.getItem("clientId");
        const resId = await createNewJobReq(clientId);
        // --------- when file is uploaded separately -----------
        // if (resId) {
        //   const ress = await uploadFile(resId);
        //   if (ress) {
        //     navigate("/applications");
        //   }
        // }
        // --------- when file is uploaded separately -----------
        // --------- when file is uploaded separately -----------
        if (resId) {
          navigate("/applications");
        }
      } else if (sessionStorage.getItem("clientType") === "edit") {
        // console.log("edit");
        const clientId = sessionStorage.getItem("clientId");
        const resId = await UpdateJob(clientId);
        if (resId) {
          toast("Successfully Updated!");
          navigate("/applications");
        }
      }
    } catch (err) {
      console.log("FlowForPage error ==>", err);
      toast(err.message);
    }
  };

  useEffect(() => {
    allCountries();
    if (sessionStorage.getItem("clientType") === "edit") {
      preFillDetails();
    }
  }, []);

  return (
    <>
      <div className="create-job">
        <div className="backButton">
          <button onClick={() => navigate("/applications")}>
            <ChevronLeftIcon /> <span> Applications </span>
          </button>
        </div>
        <div className="title">Job requirement</div>
        <Allfields
          fieldValues={fieldValues}
          setFieldsValues={setFieldsValues}
          countries={countries}
        />
        <JdUpload
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
        <div>
          <button className="submit" onClick={() => FlowForPage()}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

const Allfields = ({ fieldValues, setFieldsValues, countries }) => {
  const roleOptions = [
    "Full Stack Developer",
    "Backend",
    "Frontend",
    "Data Science Engineer",
  ];
  const typeOfEng = ["WFH", "WFO", "Hybrid"];
  const skills = [
    "Java",
    "Javascript",
    "React",
    "Angular",
    "React Js",
    "My SQL",
  ];
  const handleChange = (e) => {
    const temp = fieldValues;
    temp[e.target.name] = e.target.value;
    setFieldsValues({ ...temp });
  };

  const handleDropDownChange = (val, name) => {
    // console.log(val);

    const temp = fieldValues;
    temp[name] = val.value;
    setFieldsValues({ ...temp });
  };
  const handleChangeMultiple = (event) => {
    const {
      target: { value },
    } = event;
    setFieldsValues((prev) => {
      return { ...prev, ["mandarotySkill"]: value };
    });
  };

  const handleChangeExtraSkill = (event) => {
    const {
      target: { value },
    } = event;
    setFieldsValues((prev) => {
      return { ...prev, ["additionalSkill"]: value };
    });
  };
  return (
    <div className="allfieldsJob">
      <div className="outer">
        <div className="inner1">
          <label>Job Role</label>
          <Dropdown
            options={roleOptions}
            onChange={(e) => handleDropDownChange(e, "jobRole")}
            name="jobRole"
            value={fieldValues?.jobRole}
            placeholder="Job Role"
            className="dropdown"
            controlClassName="myControlClassName"
            menuClassName="myMenuClassName"
            arrowClassName="myArrowClassName"
          />
        </div>
        <div className="inner1">
          <label>Designation</label>
          <input
            type="text"
            value={fieldValues?.designation}
            name="designation"
            onChange={handleChange}
            placeholder="Type designation"
          />
        </div>
      </div>
      <div className="outer">
        <div className="inner1">
          <label>Business unit</label>
          <input
            type="text"
            value={fieldValues?.businessUnit}
            name="businessUnit"
            onChange={handleChange}
            placeholder="Business unit"
          />
        </div>
        <div className="inner1">
          <label>Country</label>
          <Dropdown
            options={countries}
            onChange={(e) => handleDropDownChange(e, "country")}
            name="country"
            value={fieldValues?.country}
            placeholder="Country"
            className="dropdown"
            controlClassName="myControlClassName"
            menuClassName="myMenuClassName"
            arrowClassName="myArrowClassName"
          />
        </div>
        <div className="inner1">
          <label>State</label>

          <input
            type="text"
            value={fieldValues?.state}
            name="state"
            onChange={handleChange}
            placeholder="State"
          />
        </div>
      </div>
      <div className="outer">
        <div className="inner1">
          <label>Work location</label>
          <input
            type="text"
            value={fieldValues?.workLocation}
            name="workLocation"
            onChange={handleChange}
            placeholder="Work Location"
          />
        </div>
        <div className="inner1">
          <label>Type of engagement </label>
          <Dropdown
            options={typeOfEng}
            onChange={(e) => handleDropDownChange(e, "typeOfEngagement")}
            name="typeOfEngagement"
            value={fieldValues?.typeOfEngagement}
            placeholder="type of engagement"
            className="dropdown"
            controlClassName="myControlClassName"
            menuClassName="myMenuClassName"
            arrowClassName="myArrowClassName"
          />
        </div>
      </div>
      <div className="outer">
        <div className="inner1">
          <label>Minimum year of experience</label>
          <input
            type="number"
            onChange={handleChange}
            name="minYearsOfExperience"
            value={fieldValues?.minYearsOfExperience}
            placeholder="Minimum year of experience"
            min={0}
            max={30}
          />
        </div>
        <div className="inner1">
          <label>Mandatory Skills*</label>
          <Select
            multiple
            value={fieldValues?.mandarotySkill}
            onChange={handleChangeMultiple}
            // MenuProps={skills}
          >
            {skills.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className="outer">
        <div className="inner1">
          <label>Additional skills</label>
          <Select
            multiple
            value={fieldValues?.additionalSkill}
            onChange={handleChangeExtraSkill}
            // MenuProps={skills}
          >
            {skills.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="inner1">
          <label>Availability</label>
          <input
            type="date"
            name="availibility"
            value={fieldValues?.availibility}
            onChange={handleChange}
            placeholder="availibility"
          />
        </div>
      </div>
      <div className="outer">
        <div className="inner1">
          <label>Minimum Candidate Rating Required</label>
          <input
            type="number"
            onChange={handleChange}
            value={fieldValues?.minimumRating}
            name="minimumRating"
            placeholder="minimum ratings"
            min={1}
            max={5}
          />
        </div>
        <div className="inner1">
          <label>No of openings</label>
          <input
            type="number"
            onChange={handleChange}
            value={fieldValues?.numberOfOpenings}
            name="numberOfOpenings"
            placeholder="no of openings"
            min={1}
            max={100}
          />
        </div>
      </div>
    </div>
  );
};

const JdUpload = ({ selectedFile, setSelectedFile }) => {
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size === 0) {
      toast("File is empty");
    } else if (file && file.size > 10 * 1024 * 1024) {
      toast("File size exceeds the 10MB limit.");
    } else {
      setSelectedFile(file);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.size === 0) {
      toast("File is empty");
    } else if (file && file.size > 10 * 1024 * 1024) {
      toast("File size exceeds the 10MB limit.");
    } else {
      setSelectedFile(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div className="Jdmain">
      <div className="title2">Job Description</div>
      <div>
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className="dropBox"
        >
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={handleFileChange}
            accept=".pdf"
          />
          <label htmlFor="fileInput" className="label1">
            Drag and drop JD here (Max 10MB)
          </label>
          <label htmlFor="fileInput" className="label2">
            {" "}
            or click here to upload JD
          </label>
          {selectedFile && (
            <p className="selected">Selected PDF: {selectedFile.name}</p>
          )}
        </div>
      </div>
    </div>
  );
};

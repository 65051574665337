import axios from "axios";
import { CREATE_JOB, CREATE_JOB_UPLOAD_FILE, UPDATE_JOB } from "./URLs";

// when only for job creation
export const creNewJobRequirement = async (jobData, clientId) => {
  for (const key in jobData) {
    if (jobData[key] === "") {
      throw new Error(`Field '${key}' cannot be empty.`);
    }
  }

  const Config = {};

  const apiURL = CREATE_JOB + `/${clientId}`;

  return await axios.post(apiURL, jobData, Config).then((res) => res.data);
};

// when job creation and file upload works together
export const createJobAndUploadJd = async (jobData, selectedFile, clientId) => {
  for (const key in jobData) {
    if (jobData[key] === "") {
      throw new Error(`Field '${key}' cannot be empty.`);
    }
  }

  const apiURL = CREATE_JOB_UPLOAD_FILE + `/${clientId}`;

  const formData = new FormData();

  formData.append("jsonData", JSON.stringify(jobData));
  formData.append("file", selectedFile);
  return await axios
    .post(apiURL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};

// update existing job
export const updateExistingJob = async (jobData, selectedFile, clientId) => {
  for (const key in jobData) {
    if (jobData[key] === "") {
      throw new Error(`Field '${key}' cannot be empty.`);
    }
  }

  const apiURL = UPDATE_JOB + `/${clientId}`;

  const formData = new FormData();

  formData.append("jsonData", JSON.stringify(jobData));
  if (selectedFile) {
    formData.append("file", selectedFile);
  }
  return await axios
    .put(apiURL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};

import axios from "axios";
import { CLIENT_DETAILS } from "./URLs";

export const getClientDetailsApi = async (qeuryParams) => {
  const apiUrl = CLIENT_DETAILS;

  // Use the `params` property to include query parameters in the GET request
  return await axios
    .get(apiUrl, {
      params: qeuryParams,
    })
    .then((response) => response);
};

import "./ViewClient.scss";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import Pagination from "@mui/material/Pagination";
import { useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { getClientDetailsApi } from "../../APIs/clientDetails";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";

const headers = [
  "Date",
  "Client Name",
  "Client ID",
  "SPOC",
  "Designation",
  "Location",
];

export const ViewClient = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [tableHeaders, setTableHeaders] = useState([...headers]);
  const [clientData, setClientData] = useState([]);
  const [viewFilters, setViewFilters] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [location, setLocation] = useState("");
  const [name, setName] = useState("");
  const [cities, setCities] = useState([
    "Bangalore",
    "hyderabad",
    "delhi",
    "mumbai",
    "noida",
  ]);
  const navigate = useNavigate();

  const getClientDetails = async () => {
    try {
      setViewFilters(false);
      let queryParams = {};
      if (fromDate && toDate) {
        queryParams["fromDate"] = fromDate.split("-").reverse().join("-");
        queryParams["toDate"] = toDate.split("-").reverse().join("-");
      }
      if (location) {
        queryParams["location"] = location;
      }
      if (sessionStorage?.getItem("email")) {
      } else {
        throw new Error("please login again!");
      }
      if (currentPage) {
        queryParams["pageNumber"] = currentPage - 1;
        queryParams["pageSize"] = 10;
        // queryParams["clsmId"] = "clsm@wissda.com";
        queryParams["clsmId"] = sessionStorage.getItem("email");
      }
      if (name.length > 0) {
        // Use a regular expression to check if the first and last character is a number
        if (/^[0-9].*[0-9]$/.test(name)) {
          queryParams["clientId"] = name;
        }
        // Use a regular expression to check if the first character is a number and the last character is a letter
        else if (/^[0-9].*[a-zA-Z]$/.test(name)) {
          throw new Error("Input should be either name or number");
        } else {
          queryParams["companyName"] = name;
        }
      }
      const res = await getClientDetailsApi(queryParams);
      //   console.log("getClientDetails ==>", res);
      if (res) {
        setClientData([...res?.data]);
      }
    } catch (err) {
      console.log("getClientDetails error ==>", err);
      toast(err.message);
    }
  };

  const handleReset = async () => {
    setFromDate("");
    setToDate("");
    setLocation("");
    setViewFilters(false);
    setCurrentPage(1);
    setName("");
    let queryParams = {};

    try {
      if (sessionStorage?.getItem("email")) {
      } else {
        throw new Error("please login again!");
      }
      queryParams["pageNumber"] = 0;
      queryParams["pageSize"] = 10;
      // queryParams["clsmId"] = "clsm@wissda.com";
      queryParams["clsmId"] = sessionStorage.getItem("email");

      const res = await getClientDetailsApi(queryParams);
      //   console.log("getClientDetails ==>", res);
      if (res) {
        setClientData([...res?.data]);
      }
    } catch (err) {
      console.log("getClientDetails error ==>", err);
    }
  };

  const handleViewApplication = (clientId) => {
    sessionStorage.setItem("clientId", clientId);
    navigate("/specific-client");
  };

  const handleEdit = (clientDetails) => {
    console.log("client ==>", clientDetails);
    const formattedClient = JSON.stringify(clientDetails);
    sessionStorage.setItem("clientDetails", formattedClient);
    navigate("/create-client");
  };

  useEffect(() => {
    let timer;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      getClientDetails();
    }, 300); // Adjust the debounce delay (in milliseconds) as needed

    return () => {
      clearTimeout(timer); // Clear the timeout on unmount or when 'name' changes
    };
  }, [currentPage, name]);

  return (
    <>
      <div className="view-client">
        <div className="title">
          <div>Clients</div>
          <div>
            <button onClick={() => navigate("/create-client")}>
              <AddIcon /> New Client
            </button>
          </div>
        </div>
        <div className="searchAndFilter">
          <SearchClient name={name} setName={setName} />

          <button className="filersButton" onClick={() => setViewFilters(true)}>
            Filters <FilterListIcon />
          </button>
        </div>
        <ViewClientTable
          clientData={clientData}
          tableHeaders={tableHeaders}
          handleViewApplication={handleViewApplication}
          handleEdit={handleEdit}
        />
        <PageNum currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </div>
      {viewFilters && (
        <FiltersOptions
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          location={location}
          setLocation={setLocation}
          cities={cities}
          setViewFilters={setViewFilters}
          handleReset={handleReset}
          getClientDetails={getClientDetails}
        />
      )}
    </>
  );
};

const SearchClient = ({ name, setName }) => {
  return (
    <div className="searchMain">
      <Paper
        component="form"
        sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}
      >
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          // placeholder="Search by name, ID or designation"
          placeholder="Search by name or ID"
          inputProps={{ "aria-label": "search google maps" }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Paper>
    </div>
  );
};

const PageNum = ({ currentPage, setCurrentPage }) => {
  const handlePageChange = (event, page) => {
    // console.log(page);
    setCurrentPage(page);
  };
  return (
    <div className="pageNo">
      <Pagination
        page={currentPage}
        onChange={handlePageChange}
        count={100}
        className="pages"
      />
    </div>
  );
};

const FiltersOptions = ({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  location,
  setLocation,
  cities,
  setViewFilters,
  handleReset,
  getClientDetails,
}) => {
  const handleChange = (val) => {
    console.log(val);
    setLocation(val.value);
  };

  function handleBoxClick(e) {
    if (e.target.id === "mainBox") {
      // setViewFilters(false);
    }
  }

  return (
    <div
      className="clientFilters"
      id="mainBox"
      onClick={(e) => handleBoxClick(e)}
    >
      <div className="popupMainWrapper">
        <div className="close" onClick={() => setViewFilters(false)}>
          <img src={"./images/icons/Close.svg"} alt="cut" />
        </div>
        <div className="title">Filters</div>
        <div className="date">Date</div>
        <div className="fromTo">
          <div className="item">
            <div>From</div>
            <div>
              <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>
          </div>
          <div className="item">
            <div>To</div>
            <div>
              <input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="date">Location</div>
        <div>
          <Dropdown
            options={cities}
            onChange={handleChange}
            value={location}
            placeholder="Select an option"
            className="dropdown"
            controlClassName="myControlClassName"
            menuClassName="myMenuClassName"
          />
        </div>
        <div className="buttons">
          <div onClick={() => handleReset()}>Reset</div>
          <div onClick={() => getClientDetails()}>Save</div>
        </div>
      </div>
    </div>
  );
};

const ViewClientTable = ({
  clientData,
  tableHeaders,
  handleViewApplication,
  handleEdit,
}) => {
  return (
    <div className="clientTableWrapper">
      <table className="applicationTableContainer">
        <thead className="tableHead">
          <tr className="tableRow">
            {tableHeaders?.map((header, index) => (
              <th key={index} className="headerContent">
                {header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody className="tableBody">
          {clientData?.map((data, index) => (
            <tr key={index}>
              <td>{data?.date}</td>
              <td>{data?.companyName}</td>
              <td>{data?.clientId}</td>
              <td>{data?.spocFullName}</td>
              <td>{data?.designation}</td>
              <td>{data?.city}</td>
              <td>
                <button onClick={() => handleViewApplication(data?.clientId)}>
                  View Application
                </button>
              </td>
              <td
                style={{ cursor: "pointer" }}
                onClick={() => handleEdit(data)}
              >
                <EditIcon />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

import axios from "axios";
import { NEW_CLIENT, UPDATE_CLIENT } from "./URLs";

export const registerNewClient = async (clientData) => {
  for (const key in clientData) {
    if (clientData[key] === "") {
      throw new Error(`Field '${key}' cannot be empty.`);
    }
  }

  const URL = NEW_CLIENT;
  let Config = {};
  console.log(clientData);
  if (sessionStorage?.getItem("email")) {
  } else {
    throw new Error("please login again!");
  }
  const data = {
    spocFirstName: clientData?.spocFirst,
    spocLastName: clientData?.spocSecond,
    clientEmail: clientData?.email,
    phoneNumber: clientData?.phone,
    companyName: clientData?.companyName,
    companyWebsite: clientData?.companyWebsite,
    companyAddress: clientData?.companyAddress,
    country: clientData?.country,
    state: clientData?.state,
    city: clientData?.city,
    businessUnit: clientData?.businessUnit,
    designation: clientData?.designation,
    // clsmId: "clsm@wissda.com",
    clsmId: sessionStorage.getItem("email"),
  };

  console.log(data);
  return await axios.post(URL, data, Config).then((res) => res.data);
  //   return false;
};

export const updateExistingClient = async (clientData, clientId) => {
  for (const key in clientData) {
    if (clientData[key] === "") {
      throw new Error(`Field '${key}' cannot be empty.`);
    }
  }

  const URL = UPDATE_CLIENT + `/${clientId}`;
  console.log(URL);
  let Config = {};
  console.log(clientData);
  if (sessionStorage?.getItem("email")) {
  } else {
    throw new Error("please login again!");
  }
  const data = {
    spocFirstName: clientData?.spocFirst,
    spocLastName: clientData?.spocSecond,
    clientEmail: clientData?.email,
    phoneNumber: clientData?.phone,
    companyName: clientData?.companyName,
    companyWebsite: clientData?.companyWebsite,
    companyAddress: clientData?.companyAddress,
    country: clientData?.country,
    state: clientData?.state,
    city: clientData?.city,
    businessUnit: clientData?.businessUnit,
    designation: clientData?.designation,
    // clsmId: "clsm@wissda.com",
    clsmId: sessionStorage.getItem("email"),
  };

  console.log(data);
  return await axios.put(URL, data, Config).then((res) => res.data);
  //   return false;
};

import axios from "axios";
import { CLSM_LOGIN } from "./URLs";

export const clsmLogin = async (data) => {
  const URL = CLSM_LOGIN;

  let Config = {};
  // data["role"] = "clsm";

  console.log(data);
  return await axios.post(URL, data, Config).then((res) => res.data);
};

import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DescriptionIcon from "@mui/icons-material/Description";
import GroupIcon from "@mui/icons-material/Group";
export const SideNavBar = () => {
  const [selectedRoute, setSelectedRoute] = React.useState("");
  const navigate = useNavigate();

  const routes = [
    {
      path: "/dashboard-overview",
      name: "Dashboard",
      icon: <DashboardIcon />,
    },
    {
      path: "/view-client",
      name: "Clients",
      icon: <GroupIcon />,
    },
    {
      path: "/applications",
      name: "Applications",
      icon: <DescriptionIcon />,
    },
  ];

  function handleChangeRoute(route) {
    setSelectedRoute(route);
    navigate(route);
  }

  return (
    <>
      <Drawer variant="permanent">
        <List>
          {routes.map((route) => (
            <ListItem
              key={route.path}
              button
              onClick={() => handleChangeRoute(route.path)}
              selected={route.path === selectedRoute}
            >
              <ListItemIcon>{route.icon}</ListItemIcon>
              <ListItemText primary={route.name} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

import { useNavigate } from "react-router-dom";
import "./AllApplications.scss";
import { useEffect, useState } from "react";
import Select from "@mui/material/Select";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import Dropdown from "react-dropdown";
import { toast } from "react-toastify";
import { getAllJobApplications } from "../../APIs/getAllJobApplications";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import { handleViewJD } from "../../Common/viewJd";
import { handleDownLoadJd } from "../../Common/downloadJd";
import Pagination from "@mui/material/Pagination";
import Popup from "../../Components/common/popUp/popUp";
import { closeJobApplications } from "../../APIs/closeApplications";

const headers = [
  "Date",
  "Application ID",
  "Client Name",
  "Job Role",
  "Designation",
  "Experience",
  "Business Unit",
  "Work Location",
  "Ratings",
  "Status",
];

export const AllApplications = () => {
  const [viewFilters, setViewFilters] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [location, setLocation] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");
  const [cities, setCities] = useState([
    "Bangalore",
    "hyderabad",
    "delhi",
    "mumbai",
    "noida",
  ]);
  const [tableHeaders, setTableHeaders] = useState([...headers]);
  const [applicationData, setApplicationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [name, setName] = useState("");
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(false);
  const navigate = useNavigate("");

  const handleReset = async () => {
    setFromDate("");
    setToDate("");
    setLocation("");
    setApplicationStatus("");
    setViewFilters(false);
    setName("");
    setCurrentPage(1);

    try {
      if (sessionStorage?.getItem("email")) {
      } else {
        throw new Error("please login again!");
      }
      let params = {
        pageNumber: 0,
        pageSize: 10,
        // clsmId: "clsm@wissda.com",
        clsmId: sessionStorage.getItem("email"),
      };

      const res = await getAllJobApplications(params);
      setApplicationData([...res?.data]);
    } catch (err) {
      console.log("jobApplications error ==>", err);
      toast(err.message);
    }
  };

  function handleChangeStatus(email) {
    console.log("called", email, selectedCandidates);
    if (selectedCandidates.includes(email)) {
      // Candidate is already selected, so remove them
      setSelectedCandidates(
        selectedCandidates.filter((candidateEmail) => candidateEmail !== email)
      );
    } else {
      // Candidate is not selected, so add them
      setSelectedCandidates([...selectedCandidates, email]);
    }
  }

  // const

  const jobApplications = async () => {
    setViewFilters(false);

    try {
      if (sessionStorage?.getItem("email")) {
      } else {
        throw new Error("please login again!");
      }
      let params = {
        pageNumber: currentPage - 1,
        pageSize: 10,
        // clsmId: "clsm@wissda.com",
        clsmId: sessionStorage.getItem("email"),
      };
      if (fromDate && toDate) {
        // params["fromDate"] = fromDate.split("-").reverse().join("-");
        params["fromDate"] = fromDate;
        // params["toDate"] = toDate.split("-").reverse().join("-");
        params["toDate"] = toDate;
      }
      if (applicationStatus) {
        params["jobStatus"] = applicationStatus;
      }
      if (location) {
        params["location"] = location;
      }
      if (name.length > 0) {
        // Use a regular expression to check if the first and last character is a number
        if (/^[0-9].*[0-9]$/.test(name)) {
          params["jobId"] = name;
        }
        // Use a regular expression to check if the first character is a number and the last character is a letter
        else if (/^[0-9].*[a-zA-Z]$/.test(name)) {
          throw new Error("Input should be either name or number");
        } else {
          params["companyName"] = name;
        }
      }
      const res = await getAllJobApplications(params);
      setApplicationData([...res?.data]);
    } catch (err) {
      console.log("jobApplications error ==>", err);
      toast(err.message);
    }
  };

  const handleEditApplication = (index) => {
    // console.log("edit", applicationData[index]);
    // sessionStorage
    const formattedData = JSON.stringify(applicationData[index]);
    sessionStorage.setItem("jobData", formattedData);
    sessionStorage.setItem("clientId", applicationData[index]?.clientId);
    sessionStorage.setItem("clientType", "edit");
    navigate("/create-job");
  };

  useEffect(() => {
    let timer;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      jobApplications();
    }, 300); // Adjust the debounce delay (in milliseconds) as needed

    return () => {
      clearTimeout(timer); // Clear the timeout on unmount or when 'name' changes
    };
  }, [currentPage, name]);

  return (
    <>
      <div className="allApplications">
        <div className="title">
          <div>Applications</div>
          <div>
            <button onClick={() => navigate("/new-application")}>
              <AddIcon /> New Job Requirement
            </button>
          </div>
        </div>
        <div className="searchAndFilter">
          <SearchClient name={name} setName={setName} />

          {selectedCandidates.length > 0 && (
            <div className="mainDropdown">
              <button onClick={() => setSelectedStatus(true)}>Close</button>
            </div>
          )}

          <button className="filersButton" onClick={() => setViewFilters(true)}>
            Filters <FilterListIcon />
          </button>
        </div>
        <ViewDataTAble
          clientData={applicationData}
          tableHeaders={tableHeaders}
          handleEditApplication={handleEditApplication}
          handleChangeStatus={handleChangeStatus}
          selectedCandidates={selectedCandidates}
        />
        <PageNum currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </div>
      {viewFilters && (
        <FiltersOptions
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          location={location}
          setLocation={setLocation}
          cities={cities}
          setViewFilters={setViewFilters}
          handleReset={handleReset}
          applicationStatus={applicationStatus}
          setApplicationStatus={setApplicationStatus}
          jobApplications={jobApplications}
        />
      )}
      {selectedStatus && (
        <ShowClose
          setSelectedStatus={setSelectedStatus}
          selectedCandidates={selectedCandidates}
          jobApplications={jobApplications}
          setSelectedCandidates={setSelectedCandidates}
        />
      )}
    </>
  );
};

const SearchClient = ({ name, setName }) => {
  return (
    <div className="searchMain">
      <Paper
        component="form"
        sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}
      >
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search by name or ID"
          inputProps={{ "aria-label": "search google maps" }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Paper>
    </div>
  );
};

const FiltersOptions = ({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  location,
  setLocation,
  cities,
  setViewFilters,
  handleReset,
  applicationStatus,
  setApplicationStatus,
  jobApplications,
}) => {
  const applicationOptions = ["Active", "Closed"];

  const handleChange = (val) => {
    console.log(val);
    setLocation(val.value);
  };

  function handleBoxClick(e) {
    if (e.target.id === "mainBox") {
      setViewFilters(false);
    }
  }

  return (
    <div
      className="clientFilters"
      id="mainBox"
      onClick={(e) => handleBoxClick(e)}
    >
      <div className="popupMainWrapper">
        <div className="close" onClick={() => setViewFilters(false)}>
          <img src={"./images/icons/Close.svg"} alt="cut" />
        </div>
        <div className="title">Filters</div>
        <div className="date">Date</div>
        <div className="fromTo">
          <div className="item">
            <div>From</div>
            <div>
              <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>
          </div>
          <div className="item">
            <div>To</div>
            <div>
              <input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="date">Location</div>
        <div>
          <Dropdown
            options={cities}
            onChange={handleChange}
            value={location}
            placeholder="Select an option"
            className="dropdown"
            controlClassName="myControlClassName"
            menuClassName="myMenuClassName"
          />
        </div>

        <div className="date">Application Status</div>
        <div>
          <Dropdown
            options={applicationOptions}
            onChange={(e) => setApplicationStatus(e.value)}
            value={applicationStatus}
            placeholder="Select an option"
            className="dropdown"
            controlClassName="myControlClassName"
            menuClassName="myMenuClassName"
          />
        </div>

        <div className="buttons">
          <div onClick={() => handleReset()}>Reset</div>
          <div onClick={() => jobApplications()}>Save</div>
        </div>
      </div>
    </div>
  );
};

const ViewDataTAble = ({
  clientData,
  tableHeaders,
  handleEditApplication,
  handleChangeStatus,
  selectedCandidates,
}) => {
  return (
    <div className="jobTableWrapper">
      <table className="applicationTableContainer">
        <thead className="tableHead">
          <tr className="tableRow">
            <th></th>
            {tableHeaders?.map((header, index) => (
              <th key={index} className="headerContent">
                {header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody className="tableBody">
          {clientData?.map((data, index) => (
            <tr key={index}>
              {data?.jobStatus && data?.jobStatus === "Active" ? (
                <td>
                  <input
                    type="checkbox"
                    checked={selectedCandidates.includes(data?.jobId)}
                    onChange={() => handleChangeStatus(data?.jobId)}
                  />
                </td>
              ) : (
                <>
                  <div></div>
                </>
              )}
              <td>{data?.dateOfPosting}</td>
              <td>{data?.jobId}</td>
              <td>{data?.companyName}</td>
              <td>{data?.jobPosition}</td>
              <td>{data?.designation}</td>
              <td>{data?.minYearsOfExperience} years</td>
              <td>{data?.division}</td>
              <td>{data?.location}</td>
              <td>{data?.minimumCandidateRatings}</td>
              <td
                style={{
                  color:
                    data?.jobStatus.toLowerCase() === "active"
                      ? "green"
                      : "red",
                }}
              >
                {" "}
                {data?.jobStatus}
              </td>
              <td className="jdAndEdit">
                <span onClick={() => handleViewJD(data?.jobId)}>View JD</span>
                <span
                  onClick={() => handleDownLoadJd(data?.jobId)}
                  title="Download"
                >
                  <DownloadIcon />
                </span>
                {data?.jobStatus && data?.jobStatus === "Active" && (
                  <span title="edit">
                    {" "}
                    <EditIcon onClick={() => handleEditApplication(index)} />
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const PageNum = ({ currentPage, setCurrentPage }) => {
  const handlePageChange = (event, page) => {
    // console.log(page);
    setCurrentPage(page);
  };
  return (
    <div className="pageNo">
      <Pagination
        page={currentPage}
        onChange={handlePageChange}
        count={100}
        className="pages"
      />
    </div>
  );
};

const ShowClose = ({
  setSelectedStatus,
  selectedCandidates,
  jobApplications,
  setSelectedCandidates,
}) => {
  const handleCloseApplication = async () => {
    try {
      if (sessionStorage?.getItem("email")) {
      } else {
        throw new Error("please login again!");
      }
      const data = {
        // clsmId: "clsm@wissda.com",
        clsmId: sessionStorage.getItem("email"),
        jobIds: [...selectedCandidates],
      };
      const res = await closeJobApplications(data);
      console.log(res);
      setSelectedCandidates([]);
      jobApplications();
      setSelectedStatus(false);
    } catch (err) {
      console.log("handleCloseApplication", err);
    }
  };

  return (
    <Popup>
      {" "}
      <div className="close">
        <div className="closeX">
          <img
            onClick={() => setSelectedStatus(false)}
            src={"./images/icons/Close.svg"}
            alt=""
          />
        </div>
        <div className="title">
          Click confirm to close selected applications
        </div>
        <div>
          <button onClick={() => handleCloseApplication()}>Confirm</button>
        </div>
      </div>
    </Popup>
  );
};

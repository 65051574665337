import { useEffect, useState } from "react";
import "./SpecificClientApplications.scss";
import { getAllApplicationsOfClient } from "../../APIs/allApplicationsOfClient";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { openPdfFromApi } from "../../APIs/viewAndDownloadJDPdf";
import { useNavigate } from "react-router-dom";
const headers = [
  "Date",
  "Application ID",
  "Designation",
  "Job role",
  "Client Name",
  "Business Unit",
  "Work Location",
  "Ratings",
  "Status",
];

export const SpecificClientApplications = () => {
  const [tableHeaders, setTableHeaders] = useState([...headers]);
  const [clientData, setClientData] = useState([]);
  const navigate = useNavigate();

  const getAllApplications = async () => {
    try {
      const clientId = sessionStorage?.getItem("clientId");
      console.log(clientId);
      if (clientId) {
        const res = await getAllApplicationsOfClient(clientId);
        if (res) {
          setClientData([...res]);
        }
      }
    } catch (err) {
      console.log("getAllApplications error==>", err);
    }
  };

  const handleViewJD = async (jobId) => {
    try {
      const res = await openPdfFromApi(jobId);
      if (res) {
        console.log("pdf opened ");
      }
    } catch (err) {
      console.log("handleViewJD error==>", err);
    }
  };

  const goToHome = () => {
    navigate("/view-client");
  };

  useEffect(() => {
    getAllApplications();
  }, []);

  return (
    <>
      <div className="specificClient">
        <div className="backButton">
          <button onClick={() => goToHome()}>
            <ChevronLeftIcon /> <span> Clients </span>
          </button>
        </div>
        <div className="title">Applications</div>

        <ViewClientTable
          clientData={clientData}
          tableHeaders={tableHeaders}
          handleViewJD={handleViewJD}
        />
      </div>
    </>
  );
};

const ViewClientTable = ({ clientData, tableHeaders, handleViewJD }) => {
  return (
    <div className="Specific-client-wrapper">
      <table className="applicationTableContainer">
        <thead className="tableHead">
          <tr className="tableRow">
            {tableHeaders?.map((header, index) => (
              <th key={index} className="headerContent">
                {header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody className="tableBody">
          {clientData?.map((data, index) => (
            <tr key={index}>
              <td>{data?.dateOfPosting}</td>
              <td>{data?.jobId}</td>
              <td>{data?.designation}</td>
              <td>{data?.jobPosition}</td>
              <td>{data?.companyName}</td>
              <td>{data?.division}</td>
              <td>{data?.location}</td>
              <td>{data?.minimumCandidateRatings}</td>
              <td>{data?.jobStatus}</td>
              <td>
                <button onClick={() => handleViewJD(data?.jobId)}>
                  VIEW JD
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

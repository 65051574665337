import { openPdfFromApi } from "../APIs/viewAndDownloadJDPdf";

export const handleViewJD = async (jobId) => {
  try {
    const res = await openPdfFromApi(jobId);
    if (res) {
      console.log("pdf opened ");
    }
  } catch (err) {
    console.log("handleViewJD error==>", err);
  }
};

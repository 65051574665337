import { useNavigate } from "react-router-dom";
import "./NewJobRequirement.scss";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useState } from "react";
import Dropdown from "react-dropdown";
import { NewClientDetails } from "../../Components/NewClient/NewClient";
import { ExistingCLient } from "../../Components/ExistingClient/ExistingClient";

const clients = ["New client", "Existing client"];

export const NewJobRequirement = () => {
  const navigate = useNavigate();
  const [clientType, setClientType] = useState("");

  const handleClientChange = (val) => {
    setClientType(val.value);
  };

  return (
    <>
      <div className="newJobReq">
        <div className="backButton">
          <button onClick={() => navigate("/applications")}>
            <ChevronLeftIcon /> <span> Applications </span>
          </button>
        </div>
        <div className="title">New Job requirement</div>
        <div className="clienttype">
          <label>Client type</label>
          <Dropdown
            options={clients}
            onChange={handleClientChange}
            value={clientType}
            placeholder="Select an option"
            className="dropdown"
            controlClassName="myControlClassName"
            menuClassName="myMenuClassName"
            myArrowClassName="myArrowClassName"
          />
        </div>
        {clientType === clients[0] && <NewClientDetails />}
        {clientType === clients[1] && <ExistingCLient />}
      </div>
    </>
  );
};
